import React, { useState } from 'react';
import axios from 'axios';

const TwoFactorAuth = () => {
    const [qrCode, setQrCode] = useState('');
    const [token, setToken] = useState('');
    const [message, setMessage] = useState('');

    const enable2FA = async () => {
        try {
            const authToken = localStorage.getItem('token');
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/enable-2fa`, {}, {
                headers: { Authorization: `Bearer ${authToken}` },
            });
            setQrCode(data.qrCode);
        } catch (error) {
            setMessage('Failed to enable 2FA: ' + (error.response?.data?.error || 'Unknown error'));
        }
    };

    const verify2FA = async () => {
        try {
            const authToken = localStorage.getItem('token');
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/verify-2fa`, { token }, {
                headers: { Authorization: `Bearer ${authToken}` },
            });
            setMessage(data.success ? '2FA Verified Successfully' : '2FA Verification Failed');
        } catch (error) {
            setMessage('Failed to verify 2FA: ' + (error.response?.data?.error || 'Unknown error'));
        }
    };

    return (
        <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4">
            <h2 className="text-2xl font-bold">Two-Factor Authentication</h2>
            <button
                onClick={enable2FA}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                Enable 2FA
            </button>
            {qrCode && (
                <div className="mt-6 text-center">
                    <p className="mb-4 text-gray-700">Scan the QR code with your authenticator app:</p>
                    <img src={qrCode} alt="2FA QR Code" className="mx-auto border border-gray-300 rounded-md shadow-sm" />
                </div>
            )}
            <div>
                <label htmlFor="token" className="block text-sm font-medium text-gray-700">Enter 2FA Token</label>
                <input
                    type="text"
                    id="token"
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                    placeholder="Enter the token from your authenticator app"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
            </div>
            <button
                onClick={verify2FA}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                Verify 2FA
            </button>
            {message && (
                <p className="text-sm text-green-500 mt-4">{message}</p>
            )}
        </div>
    );
};

export default TwoFactorAuth;
