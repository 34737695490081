import React, { useEffect, useState } from 'react';
import axios from 'axios';

const StrategyMarketplace = () => {
    const [listings, setListings] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchListings = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/marketplace/listings`);
                setListings(data);
            } catch (error) {
                setMessage('Failed to load listings');
            }
        };

        fetchListings();
    }, []);

    const handlePurchase = async (strategyId) => {
        try {
            const token = localStorage.getItem('token');
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/marketplace/buy`, { strategyId }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setMessage(`Successfully purchased strategy: ${data.name}`);
            setListings(listings.filter(listing => listing._id !== strategyId));
        } catch (error) {
            setMessage('Failed to purchase strategy');
        }
    };

    return (
        <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4 sm:space-y-6">
            <h2 className="text-2xl font-bold text-center">Strategy Marketplace</h2>
            {message && <p className={`text-center text-sm ${message.includes('Failed') ? 'text-red-500' : 'text-green-500'}`}>{message}</p>}
            <ul className="space-y-4">
                {listings.length > 0 ? (
                    listings.map((listing) => (
                        <li key={listing._id} className="border p-4 rounded-md">
                            <h3 className="text-xl font-semibold">{listing.name}</h3>
                            <p className="text-gray-700">{listing.description}</p>
                            <p className="text-gray-900 font-bold">Price: ${listing.price}</p>
                            <button
                                onClick={() => handlePurchase(listing._id)}
                                className="mt-2 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Buy Strategy
                            </button>
                        </li>
                    ))
                ) : (
                    <p className="text-center text-gray-500">No strategies available for sale.</p>
                )}
            </ul>
        </div>
    );
};

export default StrategyMarketplace;
