import React, { useState } from 'react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import WalletConnect from '../components/WalletConnect';
import TwoFactorAuth from '../components/TwoFactorAuth';
import IPWhitelist from '../components/IPWhitelist';
import PortfolioSummary from '../components/PortfolioSummary';
import MarketOverview from '../components/MarketOverview';
import PerformanceMetrics from '../components/PerformanceMetrics';
import StrategyList from '../components/StrategyList';
import StrategyBuilder from '../components/StrategyBuilder';
import StrategyBacktest from '../components/StrategyBacktest';
import OrderForm from '../components/OrderForm';
import BatchTrading from '../components/BatchTrading';
import LiveMonitoring from '../components/LiveMonitoring';
import AlertSetup from '../components/AlertSetup';
import RiskManagementSetup from '../components/RiskManagementSetup';
import TradeAnalytics from '../components/TradeAnalytics';
import PortfolioAnalysis from '../components/PortfolioAnalysis';
import ReportGenerator from '../components/ReportGenerator';
import CopyTrading from '../components/CopyTrading';
import StrategyMarketplace from '../components/StrategyMarketplace';
import CommunityChat from '../components/CommunityChat';
import TradingBot from '../components/TradingBot';
import ExchangeIntegration from '../components/ExchangeIntegration';
import AssetManagement from '../components/AssetManagement';

const DashboardPage = () => {
    const [activeTab, setActiveTab] = useState('PortfolioSummary');
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const renderContent = () => {
        switch (activeTab) {
            case 'WalletConnect':
                return <WalletConnect />;
            case 'TwoFactorAuth':
                return <TwoFactorAuth />;
            case 'IPWhitelist':
                return <IPWhitelist />;
            case 'PortfolioSummary':
                return <PortfolioSummary />;
            case 'MarketOverview':
                return <MarketOverview />;
            case 'PerformanceMetrics':
                return <PerformanceMetrics />;
            case 'StrategyBuilder':
                return <StrategyBuilder />;
            case 'StrategyList':
                return <StrategyList />;
            case 'StrategyBacktest':
                return <StrategyBacktest />;
            case 'OrderForm':
                return <OrderForm />;
            case 'BatchTrading':
                return <BatchTrading />;
            case 'LiveMonitoring':
                return <LiveMonitoring />;
            case 'AlertSetup':
                return <AlertSetup />;
            case 'RiskManagementSetup':
                return <RiskManagementSetup />;
            case 'TradeAnalytics':
                return <TradeAnalytics />;
            case 'PortfolioAnalysis':
                return <PortfolioAnalysis />;
            case 'ReportGenerator':
                return <ReportGenerator />;
            case 'CopyTrading':
                return <CopyTrading />;
            case 'StrategyMarketplace':
                return <StrategyMarketplace />;
            case 'CommunityChat':
                return <CommunityChat />;
            case 'TradingBot': // Adding the TradingBot component
                return <TradingBot />;
            case 'ExchangeIntegration': // Adding the ExchangeIntegration component
                return <ExchangeIntegration />;
            case 'AssetManagement': // Adding the AssetManagement component
                return <AssetManagement />;
            default:
                return <PortfolioSummary />;
        }
    };

    return (
        <div className="flex min-h-screen bg-gray-50">
            {/* Mobile menu button */}
            <div className="lg:hidden p-4 bg-gray-800 text-white">
                <button
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                    className="focus:outline-none"
                >
                    {sidebarOpen ? <XMarkIcon className="h-6 w-6" /> : <Bars3Icon className="h-6 w-6" />}
                </button>
            </div>

            {/* Sidebar */}
            <div
                className={`fixed inset-y-0 left-0 z-30 w-64 bg-gray-800 text-white p-4 transform ${
                    sidebarOpen ? 'translate-x-0' : '-translate-x-full'
                } transition-transform duration-200 ease-in-out lg:relative lg:translate-x-0 lg:w-1/4`}
            >
                <h2 className="text-2xl font-bold mb-6">Dashboard</h2>
                <ul className="space-y-2">
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'PortfolioSummary' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('PortfolioSummary')}
                    >
                        Portfolio Summary
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'MarketOverview' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('MarketOverview')}
                    >
                        Market Overview
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'PerformanceMetrics' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('PerformanceMetrics')}
                    >
                        Performance Metrics
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'StrategyBuilder' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('StrategyBuilder')}
                    >
                        Strategy Builder
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'StrategyList' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('StrategyList')}
                    >
                        Strategy List
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'StrategyBacktest' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('StrategyBacktest')}
                    >
                        Strategy Backtest
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'OrderForm' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('OrderForm')}
                    >
                        Order Form
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'BatchTrading' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('BatchTrading')}
                    >
                        Batch Trading
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'LiveMonitoring' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('LiveMonitoring')}
                    >
                        Live Monitoring
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'AlertSetup' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('AlertSetup')}
                    >
                        Alert Setup
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'RiskManagementSetup' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('RiskManagementSetup')}
                    >
                        Risk Management
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'TradeAnalytics' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('TradeAnalytics')}
                    >
                        Trade Analytics
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'PortfolioAnalysis' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('PortfolioAnalysis')}
                    >
                        Portfolio Analysis
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'ReportGenerator' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('ReportGenerator')}
                    >
                        Report Generator
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'CopyTrading' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('CopyTrading')}
                    >
                        Copy Trading
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'StrategyMarketplace' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('StrategyMarketplace')}
                    >
                        Strategy Marketplace
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'CommunityChat' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('CommunityChat')}
                    >
                        Community Chat
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'WalletConnect' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('WalletConnect')}
                    >
                        Wallet Connect
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'TwoFactorAuth' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('TwoFactorAuth')}
                    >
                        Two-Factor Auth
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'IPWhitelist' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('IPWhitelist')}
                    >
                        IP Whitelist
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'TradingBot' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('TradingBot')}
                    >
                        Trading Bot
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'ExchangeIntegration' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('ExchangeIntegration')}
                    >
                        Exchange Integration
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'AssetManagement' && 'bg-gray-700'}`}
                        onClick={() => setActiveTab('AssetManagement')}
                    >
                        Asset Management
                    </li>
                </ul>
            </div>

            {/* Overlay for mobile when sidebar is open */}
            {sidebarOpen && (
                <div
                    className="fixed inset-0 bg-black opacity-50 lg:hidden"
                    onClick={() => setSidebarOpen(false)}
                ></div>
            )}

            {/* Main Content */}
            <div className="flex-1 p-4 lg:p-8">{renderContent()}</div>
        </div>
    );
};

export default DashboardPage;
