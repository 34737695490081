// import React from 'react';

// const ResponsiveContainer = ({ children }) => {
//     return (
//         <div className="container mx-auto px-4 sm:px-6 lg:px-8">
//             {children}
//         </div>
//     );
// };

// export default ResponsiveContainer;


import React from 'react';
import PropTypes from 'prop-types';

const ResponsiveContainer = ({ children, className = '' }) => {
    return (
        <div className={`container mx-auto px-4 sm:px-6 lg:px-8 ${className} py-6 bg-gray-50 rounded-lg shadow-md`}>
            {children}
        </div>
    );
};

ResponsiveContainer.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default ResponsiveContainer;
