import React, { useState } from 'react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Dashboard from '../components/Dashboard';
import Tutorials from '../components/Tutorials';

const UserDashboardPage = () => {
    const [activeTab, setActiveTab] = useState('Dashboard');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'Dashboard':
                return <Dashboard />;
            case 'Tutorials':
                return <Tutorials />;
            default:
                return <Dashboard />;
        }
    };

    return (
        <div className="flex min-h-screen bg-gray-50">
            {/* Sidebar */}
            <div className={`fixed inset-y-0 left-0 w-64 bg-gray-800 text-white p-4 transition-transform transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:relative md:translate-x-0`}>
                <div className="flex items-center justify-between md:hidden">
                    <h2 className="text-2xl font-bold">User Dashboard</h2>
                    <button onClick={toggleSidebar} className="text-white focus:outline-none">
                        <XMarkIcon className="w-6 h-6" />
                    </button>
                </div>
                <ul className="mt-10 space-y-2">
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'Dashboard' ? 'bg-gray-700' : ''}`}
                        onClick={() => { setActiveTab('Dashboard'); setIsSidebarOpen(false); }}
                    >
                        Dashboard Overview
                    </li>
                    <li
                        className={`hover:bg-gray-700 p-2 rounded cursor-pointer ${activeTab === 'Tutorials' ? 'bg-gray-700' : ''}`}
                        onClick={() => { setActiveTab('Tutorials'); setIsSidebarOpen(false); }}
                    >
                        Tutorials
                    </li>
                </ul>
            </div>

            {/* Main Content */}
            <div className="flex-1 p-8 md:ml-64">
                <div className="md:hidden flex justify-between items-center mb-4">
                    <button onClick={toggleSidebar} className="text-gray-700 focus:outline-none">
                        <Bars3Icon className="w-6 h-6" />
                    </button>
                    <h1 className="text-3xl font-bold">User Dashboard</h1>
                </div>

                <div className="bg-white shadow-md rounded-lg p-6">
                    <h2 className="text-2xl font-semibold mb-4">{activeTab === 'Dashboard' ? 'Dashboard Overview' : 'Tutorials'}</h2>
                    {renderContent()}
                </div>
            </div>
        </div>
    );
};

export default UserDashboardPage;
