import React, { useState } from 'react';
import axios from 'axios';

const RiskManagementSetup = () => {
    const [maxLossLimit, setMaxLossLimit] = useState('');
    const [positionSizing, setPositionSizing] = useState('');
    const [rebalanceFrequency, setRebalanceFrequency] = useState('daily');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/risk-management/set`, {
                maxLossLimit,
                positionSizing,
                rebalanceFrequency,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setMessage('Risk management settings saved');
        } catch (error) {
            setMessage('Failed to save settings');
        }
    };

    return (
        <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4 sm:space-y-6">
            <h2 className="text-2xl font-bold">Risk Management</h2>
            <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
                <div>
                    <label htmlFor="maxLossLimit" className="block text-sm font-medium text-gray-700">Max Loss Limit</label>
                    <input
                        type="number"
                        id="maxLossLimit"
                        value={maxLossLimit}
                        onChange={(e) => setMaxLossLimit(e.target.value)}
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label htmlFor="positionSizing" className="block text-sm font-medium text-gray-700">Position Sizing</label>
                    <input
                        type="number"
                        id="positionSizing"
                        value={positionSizing}
                        onChange={(e) => setPositionSizing(e.target.value)}
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label htmlFor="rebalanceFrequency" className="block text-sm font-medium text-gray-700">Rebalance Frequency</label>
                    <select
                        id="rebalanceFrequency"
                        value={rebalanceFrequency}
                        onChange={(e) => setRebalanceFrequency(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                    </select>
                </div>
                <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Save Settings
                </button>
                {message && <p className="text-sm text-green-500">{message}</p>}
            </form>
        </div>
    );
};

export default RiskManagementSetup;
