import React, { createContext, useState } from 'react';

export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
    const [account, setAccount] = useState('');

    return (
        <WalletContext.Provider value={{ account, setAccount }}>
            {children}
        </WalletContext.Provider>
    );
};
