import React, { useEffect, useState } from 'react';

const LiveMonitoring = () => {
    const [positions, setPositions] = useState([]);
    const [marketConditions, setMarketConditions] = useState({});

    useEffect(() => {
        const ws = new WebSocket('ws://localhost:5000');

        ws.onopen = () => {
            const userId = localStorage.getItem('userId');
            ws.send(JSON.stringify({ userId }));
        };

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === 'positions') {
                setPositions(data.data);
            }
            if (data.type === 'marketConditions') {
                setMarketConditions(data.data);
            }
        };

        return () => {
            ws.close();
        };
    }, []);

    return (
        <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4 sm:space-y-6">
            <h2 className="text-2xl font-bold">Live Monitoring</h2>
            <h3 className="text-xl font-semibold">Open Positions</h3>
            <ul>
                {positions.map((position, index) => (
                    <li key={index} className="mb-2">
                        {position.asset}: {position.amount} - Status: {position.status}
                    </li>
                ))}
            </ul>
            <h3 className="text-xl font-semibold">Market Conditions</h3>
            <ul>
                {Object.keys(marketConditions).map((key) => (
                    <li key={key} className="mb-2">
                        {key}: ${marketConditions[key].price} ({marketConditions[key].change}%)
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default LiveMonitoring;
