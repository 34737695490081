import React from 'react';
import ResponsiveContainer from './ResponsiveContainer';

const Tutorials = () => {
    return (
        <ResponsiveContainer>
            <h1 className="text-4xl font-bold text-center mb-12 text-gray-800">Tutorials and Documentation</h1>
            <div className="space-y-8">
                
                {/* Getting Started Section */}
                <div className="p-6 bg-white rounded shadow-md">
                    <h2 className="text-2xl font-semibold mb-4 text-indigo-600">Getting Started</h2>
                    <p className="text-gray-700 mb-4">
                        Welcome to our platform! This guide will walk you through the initial setup, including creating an account, integrating exchanges, and familiarizing yourself with the dashboard.
                    </p>
                    <ol className="list-decimal ml-6 space-y-2 text-gray-700">
                        <li><strong>Create an Account:</strong> Sign up using your email and secure your account with a strong password.</li>
                        <li><strong>Verify Your Email:</strong> Check your inbox for a verification email and confirm your account.</li>
                        <li><strong>Integrate Exchanges:</strong> Go to the exchange integration page, select your preferred exchange, and enter your API credentials.</li>
                        <li><strong>Explore the Dashboard:</strong> Familiarize yourself with the widgets and tools available on your dashboard.</li>
                    </ol>
                </div>

                {/* Customizing Your Dashboard Section */}
                <div className="p-6 bg-white rounded shadow-md">
                    <h2 className="text-2xl font-semibold mb-4 text-indigo-600">Customizing Your Dashboard</h2>
                    <p className="text-gray-700 mb-4">
                        Tailor your dashboard to suit your trading needs. Learn how to add, remove, and rearrange widgets to create a personalized trading environment.
                    </p>
                    <ul className="list-disc ml-6 space-y-2 text-gray-700">
                        <li><strong>Adding Widgets:</strong> Click on the "Add Widget" button to select new widgets from the available list.</li>
                        <li><strong>Removing Widgets:</strong> Hover over a widget and click the "Remove" button to delete it from your dashboard.</li>
                        <li><strong>Resizing Widgets:</strong> Drag the corners of a widget to resize it according to your preference.</li>
                        <li><strong>Rearranging Widgets:</strong> Drag and drop widgets to reorganize them on your dashboard.</li>
                    </ul>
                </div>

                {/* Using Multi-Exchange Features Section */}
                <div className="p-6 bg-white rounded shadow-md">
                    <h2 className="text-2xl font-semibold mb-4 text-indigo-600">Using Multi-Exchange Features</h2>
                    <p className="text-gray-700 mb-4">
                        Our platform allows you to integrate multiple exchanges, giving you a unified view of your assets and trades. This section explains how to manage assets across different exchanges.
                    </p>
                    <ul className="list-disc ml-6 space-y-2 text-gray-700">
                        <li><strong>Integrating Multiple Exchanges:</strong> Add API credentials for each exchange to view and manage assets in one place.</li>
                        <li><strong>Viewing Combined Portfolio:</strong> The dashboard provides a consolidated view of your assets across all integrated exchanges.</li>
                        <li><strong>Executing Trades:</strong> Select the desired exchange and asset, and place trades directly from your dashboard.</li>
                        <li><strong>Switching Between Exchanges:</strong> Easily switch between exchanges using the dropdown menu in the trading widget.</li>
                    </ul>
                </div>

                {/* Advanced Trading Strategies Section */}
                <div className="p-6 bg-white rounded shadow-md">
                    <h2 className="text-2xl font-semibold mb-4 text-indigo-600">Advanced Trading Strategies</h2>
                    <p className="text-gray-700 mb-4">
                        Take your trading to the next level with advanced strategies. Learn how to set up automated trading bots, backtest strategies, and use technical indicators.
                    </p>
                    <ul className="list-disc ml-6 space-y-2 text-gray-700">
                        <li><strong>Setting Up Trading Bots:</strong> Automate your trading by configuring bots to execute trades based on predefined criteria.</li>
                        <li><strong>Backtesting Strategies:</strong> Use historical data to test the effectiveness of your trading strategies before deploying them.</li>
                        <li><strong>Using Technical Indicators:</strong> Apply indicators like RSI, MACD, and moving averages to your charts for better decision-making.</li>
                        <li><strong>Custom Alerts:</strong> Set up alerts for specific market conditions or price levels to stay informed.</li>
                    </ul>
                </div>

                {/* Security Best Practices Section */}
                <div className="p-6 bg-white rounded shadow-md">
                    <h2 className="text-2xl font-semibold mb-4 text-indigo-600">Security Best Practices</h2>
                    <p className="text-gray-700 mb-4">
                        Protect your account and assets with these security best practices. Learn how to enable two-factor authentication, manage API keys, and keep your account secure.
                    </p>
                    <ul className="list-disc ml-6 space-y-2 text-gray-700">
                        <li><strong>Enable Two-Factor Authentication (2FA):</strong> Secure your account by enabling 2FA in your account settings.</li>
                        <li><strong>Manage API Keys:</strong> Regularly update and manage your API keys to prevent unauthorized access.</li>
                        <li><strong>Use Strong Passwords:</strong> Create strong, unique passwords for your account and update them regularly.</li>
                        <li><strong>Stay Informed:</strong> Keep up with the latest security practices and updates from the platform.</li>
                    </ul>
                </div>
            </div>
        </ResponsiveContainer>
    );
};

export default Tutorials;
