import React, { useEffect, useState } from 'react';
import axios from 'axios';

const StrategyList = () => {
    const [strategies, setStrategies] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchStrategies = async () => {
            try {
                const token = localStorage.getItem('token');
                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/strategies/all`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setStrategies(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching strategies:', error);
                setLoading(false);
            }
        };

        fetchStrategies();
    }, []);

    if (loading) {
        return <p className="text-center text-gray-500">Loading strategies...</p>;
    }

    return (
        <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-bold mb-4">Available Strategies</h2>
            <ul className="space-y-4">
                {strategies.map((strategy, index) => (
                    <li key={index} className="border-b border-gray-200 pb-4">
                        <h3 className="text-xl font-semibold">{strategy.name}</h3>
                        <p className="text-gray-700 mb-2">{strategy.description}</p>
                        <pre className="bg-gray-100 p-4 rounded-md text-sm overflow-auto">{JSON.stringify(strategy.parameters, null, 2)}</pre>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default StrategyList;
