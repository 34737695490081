import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ResponsiveContainer from './ResponsiveContainer';

const Dashboard = () => {
    const [widgets, setWidgets] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPreferences = async () => {
            const token = localStorage.getItem('token');
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard-preferences/get`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                if (data && data.layout && data.layout.widgets) {
                    setWidgets(data.layout.widgets);
                }
            } catch (error) {
                console.error("Error fetching preferences:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPreferences();
    }, []);

    const savePreferences = async () => {
        const token = localStorage.getItem('token');
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/dashboard-preferences/save`, { layout: { widgets } }, {
                headers: { Authorization: `Bearer ${token}` },
            });
        } catch (error) {
            console.error("Error saving preferences:", error);
        }
    };

    const addWidget = () => {
        const newWidget = { id: `widget${widgets.length + 1}`, content: `This is Widget ${widgets.length + 1}`, size: 'half' };
        setWidgets([...widgets, newWidget]);
    };

    const removeWidget = (id) => {
        setWidgets(widgets.filter(widget => widget.id !== id));
    };

    const resizeWidget = (id, size) => {
        setWidgets(widgets.map(widget => widget.id === id ? { ...widget, size } : widget));
    };

    // Save preferences whenever widgets change, but only after loading is complete
    useEffect(() => {
        if (!loading) {
            savePreferences();
        }
    }, [widgets, loading]);

    return (
        <ResponsiveContainer>
            <h1 className="text-3xl font-bold text-center mb-8">Customizable Dashboard</h1>
            {loading ? (
                <div className="flex justify-center items-center h-64">
                    <p className="text-gray-500">Loading...</p>
                </div>
            ) : (
                <>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        {widgets.map(widget => (
                            <div
                                key={widget.id}
                                className={`p-4 bg-white rounded shadow-md ${widget.size === 'full' ? 'col-span-2' : 'col-span-1'}`}
                            >
                                <div className="flex justify-between items-center">
                                    <h2 className="text-lg font-semibold">{widget.content}</h2>
                                    <div className="space-x-2">
                                        <button
                                            onClick={() => resizeWidget(widget.id, widget.size === 'full' ? 'half' : 'full')}
                                            className="text-indigo-600 hover:text-indigo-800"
                                        >
                                            {widget.size === 'full' ? 'Shrink' : 'Expand'}
                                        </button>
                                        <button
                                            onClick={() => removeWidget(widget.id)}
                                            className="text-red-600 hover:text-red-800"
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="mt-8">
                        <button
                            onClick={addWidget}
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                        >
                            Add Widget
                        </button>
                    </div>
                </>
            )}
        </ResponsiveContainer>
    );
};

export default Dashboard;
