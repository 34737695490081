import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AssetManagement = () => {
    const [assets, setAssets] = useState([]);
    const [newAsset, setNewAsset] = useState({ symbol: '', name: '', type: 'crypto', market: '', exchanges: [] });
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchAssets = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('token');
                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/assets`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setAssets(data);
                setLoading(false);
            } catch (error) {
                setError('Failed to load assets');
                setLoading(false);
            }
        };

        fetchAssets();
    }, []);

    const addAsset = async () => {
        try {
            const token = localStorage.getItem('token');
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/assets/add`, newAsset, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setAssets([...assets, data]);
            setMessage('Asset added successfully');
            setNewAsset({ symbol: '', name: '', type: 'crypto', market: '', exchanges: [] });
        } catch (error) {
            setMessage('Failed to add asset: ' + error.response?.data?.error || error.message);
        }
    };

    return (
        <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4 sm:space-y-6">
            <h2 className="text-2xl font-bold">Asset Management</h2>
            {error && <p className="text-red-500">{error}</p>}
            <div>
                <label htmlFor="symbol" className="block text-sm font-medium text-gray-700">Symbol</label>
                <input
                    type="text"
                    id="symbol"
                    value={newAsset.symbol}
                    onChange={(e) => setNewAsset({ ...newAsset, symbol: e.target.value })}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
            </div>
            <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                <input
                    type="text"
                    id="name"
                    value={newAsset.name}
                    onChange={(e) => setNewAsset({ ...newAsset, name: e.target.value })}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
            </div>
            <div>
                <label htmlFor="type" className="block text-sm font-medium text-gray-700">Type</label>
                <select
                    id="type"
                    value={newAsset.type}
                    onChange={(e) => setNewAsset({ ...newAsset, type: e.target.value })}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                    <option value="crypto">Crypto</option>
                    <option value="token">Token</option>
                    <option value="stock">Stock</option>
                    <option value="commodity">Commodity</option>
                </select>
            </div>
            {(newAsset.type === 'stock' || newAsset.type === 'commodity') && (
                <div>
                    <label htmlFor="market" className="block text-sm font-medium text-gray-700">Market</label>
                    <input
                        type="text"
                        id="market"
                        value={newAsset.market}
                        onChange={(e) => setNewAsset({ ...newAsset, market: e.target.value })}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
            )}
            <div>
                <label htmlFor="exchanges" className="block text-sm font-medium text-gray-700">Supported Exchanges</label>
                <input
                    type="text"
                    id="exchanges"
                    value={newAsset.exchanges.join(', ')}
                    onChange={(e) => setNewAsset({ ...newAsset, exchanges: e.target.value.split(', ') })}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
            </div>
            <button
                onClick={addAsset}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                Add Asset
            </button>
            {message && <p className="text-sm text-green-500 mt-4">{message}</p>}
            <ul className="space-y-4 mt-6">
                {loading ? (
                    <p>Loading assets...</p>
                ) : (
                    assets.map((asset, index) => (
                        <li key={index} className="border p-4 rounded-md">
                            <h3 className="text-xl font-semibold">{asset.name} ({asset.symbol})</h3>
                            <p>Type: {asset.type}</p>
                            {asset.market && <p>Market: {asset.market}</p>}
                            <p>Exchanges: {asset.exchanges.join(', ')}</p>
                        </li>
                    ))
                )}
            </ul>
        </div>
    );
};

export default AssetManagement;
