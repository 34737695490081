import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PortfolioSummary = () => {
    const [portfolio, setPortfolio] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPortfolio = async () => {
            try {
                const token = localStorage.getItem('token');
                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/portfolio/summary`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setPortfolio(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching portfolio summary:', error);
                setLoading(false);
            }
        };

        fetchPortfolio();
    }, []);

    if (loading) {
        return <p className="text-center text-gray-500">Loading...</p>;
    }

    return (
        <div className="bg-white shadow-md rounded-lg p-6 space-y-6 sm:space-y-8">
            <h2 className="text-2xl font-bold mb-4 text-center">Portfolio Summary</h2>
            <p className="text-lg mb-4 text-center">
                <span className="font-semibold">Total Balance:</span> ${portfolio.totalBalance?.toLocaleString()}
            </p>
            <div>
                <h3 className="text-xl font-semibold mb-2 text-center">Asset Distribution:</h3>
                <ul className="list-disc list-inside text-gray-700">
                    {portfolio.assetDistribution?.map(asset => (
                        <li key={asset.asset} className="mb-2 text-center">
                            {asset.asset}: <span className="font-medium">${asset.balance?.toLocaleString()}</span>
                        </li>
                    ))}
                </ul>
            </div>
            <div>
                <h3 className="text-xl font-semibold mb-2 text-center">Recent Trades:</h3>
                <ul className="list-disc list-inside text-gray-700">
                    {portfolio.recentTrades?.map(trade => (
                        <li key={trade._id} className="mb-2 text-center">
                            <span className="font-medium">{trade.type}</span> {trade.asset} - {trade.amount?.toLocaleString()}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default PortfolioSummary;
