import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TradingBot = () => {
    const [exchanges, setExchanges] = useState([]);
    const [selectedExchange, setSelectedExchange] = useState('');
    const [markets, setMarkets] = useState([]);
    const [selectedMarket, setSelectedMarket] = useState('');
    const [amount, setAmount] = useState('');
    const [type, setType] = useState('buy');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchExchanges = async () => {
            try {
                const token = localStorage.getItem('token');
                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/exchange/integrated`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setExchanges(data);
                if (data.length > 0) {
                    setSelectedExchange(data[0].exchangeId);
                }
            } catch (error) {
                setMessage('Failed to load exchanges. Please integrate an exchange first.');
            }
        };
        fetchExchanges();
    }, []);

    useEffect(() => {
        const fetchMarkets = async () => {
            try {
                const token = localStorage.getItem('token');
                const { data } = await axios.post(
                    `${process.env.REACT_APP_API_URL}/api/exchange/markets`,
                    { exchangeId: selectedExchange },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
    
                console.log("Fetched markets:", data);
    
                // Convert the object of markets into an array
                const marketArray = Object.keys(data).map(key => ({
                    symbol: key,
                    ...data[key]
                }));
    
                setMarkets(marketArray);
            } catch (error) {
                console.error("Error fetching markets:", error.message);
                setMessage('Failed to load markets.');
            }
        };
    
        if (selectedExchange) {
            fetchMarkets();
        }
    }, [selectedExchange]);
    
    

    const handleTrade = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const { data } = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/trading/trade`,
                { exchangeId: selectedExchange, symbol: selectedMarket, amount, type },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setMessage('Trade executed successfully');
        } catch (error) {
            setMessage('Trade failed: ' + (error.response?.data?.error || error.message));
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <form onSubmit={handleTrade} className="bg-white shadow-md rounded-lg p-6 max-w-md mx-auto">
            <h2 className="text-2xl font-bold mb-4">Execute Trade</h2>
            {exchanges.length > 0 ? (
                <>
                    <div className="mb-4">
                        <label htmlFor="exchange" className="block text-sm font-medium text-gray-700">Select Exchange</label>
                        <select
                            id="exchange"
                            value={selectedExchange}
                            onChange={(e) => setSelectedExchange(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                            {exchanges.map((exchange) => (
                                <option key={exchange._id} value={exchange.exchangeId}>
                                    {exchange.exchangeId.charAt(0).toUpperCase() + exchange.exchangeId.slice(1)}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-4">
    <label htmlFor="market" className="block text-sm font-medium text-gray-700">Select Market</label>
    <select
    id="market"
    value={selectedMarket}
    onChange={(e) => setSelectedMarket(e.target.value)}
    className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
>
    <option value="">Select Market</option>
    {markets.map((market) => (
        <option key={market.symbol} value={market.symbol}>
            {market.symbol} ({market.base}/{market.quote})
        </option>
    ))}
</select>

</div>

                    <div className="mb-4">
                        <label htmlFor="amount" className="block text-sm font-medium text-gray-700">Amount</label>
                        <input
                            type="number"
                            id="amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder="Amount"
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="type" className="block text-sm font-medium text-gray-700">Trade Type</label>
                        <select
                            id="type"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                            <option value="buy">Buy</option>
                            <option value="sell">Sell</option>
                        </select>
                    </div>
                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        {loading ? 'Executing...' : 'Execute Trade'}
                    </button>
                    {message && <p className="text-center mt-4 text-gray-700">{message}</p>}
                </>
            ) : (
                <p className="text-center text-red-500">No exchanges integrated. Please <a href="/exchange-integration" className="underline">integrate an exchange</a> first.</p>
            )}
        </form>
    );
};

export default TradingBot;
