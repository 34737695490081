import React, { useEffect, useState } from 'react';
import axios from 'axios';

const MarketOverview = () => {
    const [marketData, setMarketData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMarketData = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/market/overview`);
                setMarketData(data || {});
                setLoading(false);
            } catch (error) {
                setError('Failed to fetch market data.');
                setLoading(false);
            }
        };

        fetchMarketData();
    }, []);

    if (loading) {
        return <p className="text-center text-gray-500">Loading...</p>;
    }

    if (error) {
        return <p className="text-center text-red-500">{error}</p>;
    }

    const totalMarketCap = marketData.marketOverview?.data?.total_market_cap?.usd?.toLocaleString() || 'N/A';
    const totalVolume = marketData.marketOverview?.data?.total_volume?.usd?.toLocaleString() || 'N/A';

    return (
        <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-bold mb-4">Market Overview</h2>
            <div className="mb-6">
                <p className="text-lg">
                    <span className="font-semibold">Total Market Cap:</span> ${totalMarketCap}
                </p>
                <p className="text-lg">
                    <span className="font-semibold">24h Volume:</span> ${totalVolume}
                </p>
            </div>
            <h3 className="text-xl font-semibold mb-2">Top Gainers:</h3>
            <ul className="list-disc list-inside">
                {marketData.topGainers?.length > 0 ? (
                    marketData.topGainers.map(coin => (
                        <li key={coin.id} className="text-gray-700">
                            {coin.name} ({coin.symbol.toUpperCase()}): 
                            <span className={`${coin.price_change_percentage_24h >= 0 ? 'text-green-500' : 'text-red-500'} ml-2`}>
                                {coin.price_change_percentage_24h.toFixed(2)}%
                            </span>
                        </li>
                    ))
                ) : (
                    <li className="text-gray-500">No data available</li>
                )}
            </ul>
        </div>
    );
};

export default MarketOverview;
