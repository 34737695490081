import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

const Header = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    return (
        <header className="bg-gray-900 text-white p-4">
            <div className="container mx-auto flex justify-between items-center">
                <div className="flex items-center space-x-4">
                    <h1 className="text-2xl font-bold">
                        <Link to="/">Small Cap AI</Link>
                    </h1>
                    <span className="hidden md:block text-sm text-gray-400">AI-Driven Trading Bot</span>
                </div>

                {/* Hamburger Menu for mobile */}
                <div className="md:hidden flex items-center">
                    <button onClick={toggleMobileMenu}>
                        {isMobileMenuOpen ? (
                            <XMarkIcon className="h-6 w-6 text-white" />
                        ) : (
                            <Bars3Icon className="h-6 w-6 text-white" />
                        )}
                    </button>
                </div>

                {/* Desktop Navigation */}
                <nav className="hidden md:flex">
                    <ul className="flex space-x-6 items-center">
                        <li>
                            <Link to="/" className="hover:text-gray-300">Dashboard</Link>
                        </li>
                        <li>
                            <Link to="/user-dashboard" className="hover:text-gray-300">Tutorials</Link>
                        </li>
                        <li className="relative group">
                            <span className="hover:text-gray-300 cursor-pointer">Pages</span>
                            <ul className="absolute hidden group-hover:block bg-gray-800 mt-2 rounded shadow-lg space-y-2 p-3 z-10">
                                <li>
                                    <Link to="/developer-dashboard" className="block hover:text-gray-300">Developers</Link>
                                </li>
                                <li>
                                    <Link to="/compliance-dashboard" className="block hover:text-gray-300">Compliance</Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>

                {/* Login, Register, and Logout Links */}
                <div className="hidden md:flex space-x-4">
                    {token ? (
                        <button
                            onClick={handleLogout}
                            className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded"
                        >
                            Logout
                        </button>
                    ) : (
                        <>
                            <Link to="/login" className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded">Login</Link>
                            <Link to="/register" className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded">Register</Link>
                        </>
                    )}
                </div>
            </div>

            {/* Mobile Navigation */}
            {isMobileMenuOpen && (
                <nav className="md:hidden">
                    <ul className="space-y-4 mt-4">
                        <li>
                            <Link to="/" onClick={toggleMobileMenu} className="block text-center hover:bg-gray-800 p-2 rounded">User Dashboard</Link>
                        </li>
                        <li>
                            <Link to="/dashboard" onClick={toggleMobileMenu} className="block text-center hover:bg-gray-800 p-2 rounded">Main Dashboard</Link>
                        </li>
                        <li className="relative">
                            <span className="block text-center cursor-pointer">Dashboards</span>
                            <ul className="space-y-2 mt-2">
                                <li>
                                    <Link to="/developer-dashboard" onClick={toggleMobileMenu} className="block text-center hover:bg-gray-800 p-2 rounded">Developer Dashboard</Link>
                                </li>
                                <li>
                                    <Link to="/compliance-dashboard" onClick={toggleMobileMenu} className="block text-center hover:bg-gray-800 p-2 rounded">Compliance Dashboard</Link>
                                </li>
                            </ul>
                        </li>
                        {token ? (
                            <li>
                                <button
                                    onClick={() => { handleLogout(); toggleMobileMenu(); }}
                                    className="block w-full text-center bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded"
                                >
                                    Logout
                                </button>
                            </li>
                        ) : (
                            <>
                                <li>
                                    <Link to="/login" onClick={toggleMobileMenu} className="block text-center bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded">Login</Link>
                                </li>
                                <li>
                                    <Link to="/register" onClick={toggleMobileMenu} className="block text-center bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded">Register</Link>
                                </li>
                            </>
                        )}
                    </ul>
                </nav>
            )}
        </header>
    );
};

export default Header;
