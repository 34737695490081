import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BatchTrading = () => {
    const [exchanges, setExchanges] = useState([]);
    const [selectedExchange, setSelectedExchange] = useState('');
    const [markets, setMarkets] = useState([]);
    const [orders, setOrders] = useState([{ market: '', amount: '', orderType: 'market' }]);
    const [message, setMessage] = useState('');
    const [user, setUser] = useState(null);

    useEffect(() => {
        // Fetch integrated exchanges and user data on component mount
        const fetchExchangesAndUser = async () => {
            try {
                const token = localStorage.getItem('token');
                const [exchangesData, userData] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_URL}/api/exchange/integrated`, {
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                    axios.get(`${process.env.REACT_APP_API_URL}/api/auth/me`, {
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                ]);
                setExchanges(exchangesData.data);
                setUser(userData.data);
                if (exchangesData.data.length > 0) {
                    setSelectedExchange(exchangesData.data[0].exchangeId);
                }
            } catch (error) {
                setMessage('Failed to load exchanges or user data');
            }
        };

        fetchExchangesAndUser();
    }, []);

    useEffect(() => {
        // Fetch markets when an exchange is selected
        const fetchMarkets = async () => {
            try {
                const token = localStorage.getItem('token');
                const { data } = await axios.post(
                    `${process.env.REACT_APP_API_URL}/api/exchange/markets`,
                    { exchangeId: selectedExchange },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                setMarkets(Object.keys(data));
            } catch (error) {
                setMessage('Failed to load markets');
            }
        };

        if (selectedExchange) {
            fetchMarkets();
        }
    }, [selectedExchange]);

    const addOrder = () => {
        setOrders([...orders, { market: '', amount: '', orderType: 'market' }]);
    };

    const removeOrder = (index) => {
        const updatedOrders = orders.filter((_, i) => i !== index);
        setOrders(updatedOrders);
    };

    const handleOrderChange = (index, field, value) => {
        const updatedOrders = orders.map((order, i) => (
            i === index ? { ...order, [field]: value } : order
        ));
        setOrders(updatedOrders);
    };

    const handleBatchSubmit = async (e) => {
        e.preventDefault();
        if (!user) {
            setMessage('User not authenticated');
            return;
        }
        try {
            const token = localStorage.getItem('token');
            const ordersWithUser = orders.map(order => ({
                ...order,
                userId: user._id,  // Ensure user ID is included
                exchangeId: selectedExchange,  // Ensure exchange ID is included
            }));
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/orders/batch`, { orders: ordersWithUser }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setMessage('Batch orders executed successfully');
        } catch (error) {
            setMessage('Failed to execute batch orders');
        }
    };
    

    return (
        <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4 sm:space-y-6">
            <h2 className="text-2xl font-bold">Batch Trading</h2>

            {/* Exchange selection */}
            <div className="mb-4">
                <label htmlFor="exchange" className="block text-sm font-medium text-gray-700">Select Exchange</label>
                <select
                    id="exchange"
                    value={selectedExchange}
                    onChange={(e) => setSelectedExchange(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                    {exchanges.map((exchange) => (
                        <option key={exchange._id} value={exchange.exchangeId}>
                            {exchange.exchangeId.charAt(0).toUpperCase() + exchange.exchangeId.slice(1)}
                        </option>
                    ))}
                </select>
            </div>

            <form onSubmit={handleBatchSubmit} className="space-y-4 sm:space-y-6">
                {orders.map((order, index) => (
                    <div key={index} className="space-y-2 sm:space-y-4">
                        <div>
                            <label htmlFor={`market-${index}`} className="block text-sm font-medium text-gray-700">Market</label>
                            <select
                                id={`market-${index}`}
                                value={order.market}
                                onChange={(e) => handleOrderChange(index, 'market', e.target.value)}
                                required
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                <option value="">Select Market</option>
                                {markets.map((market) => (
                                    <option key={market} value={market}>
                                        {market}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor={`amount-${index}`} className="block text-sm font-medium text-gray-700">Amount</label>
                            <input
                                type="number"
                                id={`amount-${index}`}
                                value={order.amount}
                                onChange={(e) => handleOrderChange(index, 'amount', e.target.value)}
                                required
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                        <div>
                            <label htmlFor={`orderType-${index}`} className="block text-sm font-medium text-gray-700">Order Type</label>
                            <select
                                id={`orderType-${index}`}
                                value={order.orderType}
                                onChange={(e) => handleOrderChange(index, 'orderType', e.target.value)}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                <option value="market">Market</option>
                                <option value="limit">Limit</option>
                                <option value="stop-loss">Stop-Loss</option>
                                <option value="take-profit">Take-Profit</option>
                                <option value="trailing-stop">Trailing Stop</option>
                            </select>
                        </div>
                        {order.orderType === 'limit' && (
                            <div>
                                <label htmlFor={`limitPrice-${index}`} className="block text-sm font-medium text-gray-700">Limit Price</label>
                                <input
                                    type="number"
                                    id={`limitPrice-${index}`}
                                    value={order.limitPrice}
                                    onChange={(e) => handleOrderChange(index, 'limitPrice', e.target.value)}
                                    required
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                        )}
                        {order.orderType === 'stop-loss' && (
                            <div>
                                <label htmlFor={`stopPrice-${index}`} className="block text-sm font-medium text-gray-700">Stop Price</label>
                                <input
                                    type="number"
                                    id={`stopPrice-${index}`}
                                    value={order.stopPrice}
                                    onChange={(e) => handleOrderChange(index, 'stopPrice', e.target.value)}
                                    required
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                        )}
                        {order.orderType === 'trailing-stop' && (
                            <div>
                                <label htmlFor={`trailingStopDistance-${index}`} className="block text-sm font-medium text-gray-700">Trailing Stop Distance</label>
                                <input
                                    type="number"
                                    id={`trailingStopDistance-${index}`}
                                    value={order.trailingStopDistance}
                                    onChange={(e) => handleOrderChange(index, 'trailingStopDistance', e.target.value)}
                                    required
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                        )}
                        <button
                            type="button"
                            onClick={() => removeOrder(index)}
                            className="text-red-500 hover:text-red-700 text-sm"
                        >
                            Remove Order
                        </button>
                    </div>
                ))}
                <button
                    type="button"
                    onClick={addOrder}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                    Add Another Order
                </button>
                <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Execute Batch Orders
                </button>
                {message && <p className="text-sm text-green-500">{message}</p>}
            </form>
        </div>
    );
};

export default BatchTrading;
