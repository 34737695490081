import React, { useState, useEffect } from 'react';
import axios from 'axios';

const WebhookManager = () => {
    const [webhooks, setWebhooks] = useState([]);
    const [url, setUrl] = useState('');
    const [event, setEvent] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchWebhooks = async () => {
            try {
                const token = localStorage.getItem('token');
                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/webhook`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setWebhooks(data);
            } catch (error) {
                console.error("Error fetching webhooks:", error);
                setMessage('Failed to load webhooks.');
            }
        };

        fetchWebhooks();
    }, []);

    const registerWebhook = async () => {
        if (!url || !event) {
            setMessage('Please enter a valid URL and event type');
            return;
        }

        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/webhook/register`, { url, event }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setWebhooks([...webhooks, data]);
            setMessage('Webhook registered successfully');
            setUrl('');
            setEvent('');
        } catch (error) {
            console.error("Error registering webhook:", error);
            setMessage('Failed to register webhook.');
        } finally {
            setLoading(false);
        }
    };

    const removeWebhook = async (id) => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/webhook/remove/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setWebhooks(webhooks.filter(webhook => webhook._id !== id));
            setMessage('Webhook removed successfully');
        } catch (error) {
            console.error("Error removing webhook:", error);
            setMessage('Failed to remove webhook.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4 sm:space-y-6">
            <h2 className="text-2xl font-bold">Webhook Management</h2>
            <div>
                <label htmlFor="url" className="block text-sm font-medium text-gray-700">Webhook URL</label>
                <input
                    type="text"
                    id="url"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
            </div>
            <div>
                <label htmlFor="event" className="block text-sm font-medium text-gray-700">Event Type</label>
                <input
                    type="text"
                    id="event"
                    value={event}
                    onChange={(e) => setEvent(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
            </div>
            <button
                onClick={registerWebhook}
                disabled={loading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                {loading ? 'Registering...' : 'Register Webhook'}
            </button>
            <ul className="space-y-4 mt-4">
                {webhooks.map(webhook => (
                    <li key={webhook._id} className="border p-4 rounded-md">
                        <p><strong>URL:</strong> {webhook.url}</p>
                        <p><strong>Event:</strong> {webhook.event}</p>
                        <button
                            onClick={() => removeWebhook(webhook._id)}
                            className="text-red-600 hover:text-red-800"
                            disabled={loading}
                        >
                            Remove
                        </button>
                    </li>
                ))}
            </ul>
            {message && <p className="text-sm text-green-500">{message}</p>}
        </div>
    );
};

export default WebhookManager;
