import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';


import { WalletProvider } from './context/WalletContext'; // Import the WalletProvider

// Import your components and pages
import DashboardPage from './pages/DashboardPage';
import DeveloperDashboardPage from './pages/DeveloperDashboardPage';
import ComplianceDashboardPage from './pages/ComplianceDashboardPage';
import UserDashboardPage from './pages/UserDashboardPage';
import Header from './components/Header';
import Footer from './components/Footer';

// Import individual components
import WalletConnect from './components/WalletConnect';
import PortfolioSummary from './components/PortfolioSummary';
import MarketOverview from './components/MarketOverview';
import PerformanceMetrics from './components/PerformanceMetrics';
import StrategyList from './components/StrategyList';
import StrategyBuilder from './components/StrategyBuilder';
import StrategyBacktest from './components/StrategyBacktest';
import OrderForm from './components/OrderForm';
import BatchTrading from './components/BatchTrading';
import LiveMonitoring from './components/LiveMonitoring';
import AlertSetup from './components/AlertSetup';
import RiskManagementSetup from './components/RiskManagementSetup';
import TradeAnalytics from './components/TradeAnalytics';
import PortfolioAnalysis from './components/PortfolioAnalysis';
import ReportGenerator from './components/ReportGenerator';
import CopyTrading from './components/CopyTrading';
import StrategyMarketplace from './components/StrategyMarketplace';
import CommunityChat from './components/CommunityChat';
import Login from './components/Login';
import Register from './components/Register';
import ApiKeyManager from './components/ApiKeyManager';
import WebhookManager from './components/WebhookManager';
import AssetManagement from './components/AssetManagement';
import ExchangeIntegration from './components/ExchangeIntegration'; // Import ExchangeIntegration
import KycProcess from './components/KycProcess';
import AuditLogViewer from './components/AuditLogViewer';
import TwoFactorAuth from './components/TwoFactorAuth';
import IPWhitelist from './components/IPWhitelist';


// Higher-Order Component for Protected Routes
const ProtectedRoute = ({ element, ...rest }) => {
  const isAuthenticated = !!localStorage.getItem('token'); // Check if the token exists
  return isAuthenticated ? element : <Navigate to="/login" replace />;
};

function App() {
  return (
    <WalletProvider>
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header /> {/* Site-wide header */}

        <main className="flex-grow bg-gray-100 p-4">
          <Routes>
            {/* Protected Routes */}
            <Route path="/" element={<ProtectedRoute element={<DashboardPage />} />} />
            <Route path="/user-dashboard" element={<ProtectedRoute element={<UserDashboardPage />} />} />
            <Route path="/developer-dashboard" element={<ProtectedRoute element={<DeveloperDashboardPage />} />} />
            <Route path="/compliance-dashboard" element={<ProtectedRoute element={<ComplianceDashboardPage />} />} />
            <Route path="/wallet-connect" element={<ProtectedRoute element={<WalletConnect />} />} />
            <Route path="/portfolio-summary" element={<ProtectedRoute element={<PortfolioSummary />} />} />
            <Route path="/market-overview" element={<ProtectedRoute element={<MarketOverview />} />} />
            <Route path="/performance-metrics" element={<ProtectedRoute element={<PerformanceMetrics />} />} />
            <Route path="/strategy-list" element={<ProtectedRoute element={<StrategyList />} />} />
            <Route path="/strategy-builder" element={<ProtectedRoute element={<StrategyBuilder />} />} />
            <Route path="/strategy-backtest" element={<ProtectedRoute element={<StrategyBacktest />} />} />
            <Route path="/order-form" element={<ProtectedRoute element={<OrderForm />} />} />
            <Route path="/batch-trading" element={<ProtectedRoute element={<BatchTrading />} />} />
            <Route path="/live-monitoring" element={<ProtectedRoute element={<LiveMonitoring />} />} />
            <Route path="/alert-setup" element={<ProtectedRoute element={<AlertSetup />} />} />
            <Route path="/risk-management-setup" element={<ProtectedRoute element={<RiskManagementSetup />} />} />
            <Route path="/trade-analytics" element={<ProtectedRoute element={<TradeAnalytics />} />} />
            <Route path="/portfolio-analysis" element={<ProtectedRoute element={<PortfolioAnalysis />} />} />
            <Route path="/report-generator" element={<ProtectedRoute element={<ReportGenerator />} />} />
            <Route path="/copy-trading" element={<ProtectedRoute element={<CopyTrading />} />} />
            <Route path="/strategy-marketplace" element={<ProtectedRoute element={<StrategyMarketplace />} />} />
            <Route path="/community-chat" element={<ProtectedRoute element={<CommunityChat />} />} />
            <Route path="/api-key-manager" element={<ProtectedRoute element={<ApiKeyManager />} />} />
            <Route path="/webhook-manager" element={<ProtectedRoute element={<WebhookManager />} />} />
            <Route path="/exchange-integration" element={<ProtectedRoute element={<ExchangeIntegration />} />} /> {/* Add this line */}
            <Route path="/asset-management" element={<ProtectedRoute element={<AssetManagement />} />} />
            <Route path="/kyc-process" element={<ProtectedRoute element={<KycProcess />} />} />
            <Route path="/audit-log-viewer" element={<ProtectedRoute element={<AuditLogViewer />} />} />
            <Route path="/two-factor-auth" element={<ProtectedRoute element={<TwoFactorAuth />} />} />
            <Route path="/ip-whitelist" element={<ProtectedRoute element={<IPWhitelist />} />} />

            {/* Authentication Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
          </Routes>
        </main>

        <Footer /> {/* Site-wide footer */}
      </div>
    </Router>
    </WalletProvider>
  );
}

export default App;
