import React, { useState, useEffect } from 'react';
import axios from 'axios';

const KycProcess = () => {
    const [kycStatus, setKycStatus] = useState('pending');
    const [message, setMessage] = useState('');
    const [applicantId, setApplicantId] = useState('');

    useEffect(() => {
        if (applicantId) {
            checkKycStatus();
        }
    }, [applicantId]);

    const initiateKyc = async () => {
        try {
            const token = localStorage.getItem('token');
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/kyc/initiate`, { externalUserId: 'your-user-id' }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setKycStatus(data.review.result);
            setApplicantId(data.id);
            setMessage('KYC process initiated');
        } catch (error) {
            console.error('KYC initiation error:', error.response ? error.response.data : error.message);
            setMessage('Failed to initiate KYC');
        }
    };
    

    const checkKycStatus = async () => {
        try {
            const token = localStorage.getItem('token');
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/kyc/status/${applicantId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setKycStatus(data.review.result);
            setMessage(`KYC status: ${data.review.result}`);
        } catch (error) {
            setMessage('Failed to check KYC status');
        }
    };

    return (
        <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4 sm:space-y-6">
            <h2 className="text-2xl font-bold text-center">KYC Process</h2>
            <p className="text-center text-sm text-gray-700">Current Status: <span className={`font-semibold ${kycStatus === 'completed' ? 'text-green-500' : kycStatus === 'rejected' ? 'text-red-500' : 'text-yellow-500'}`}>{kycStatus}</span></p>
            <button
                onClick={initiateKyc}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                Start KYC
            </button>
            <button
                onClick={checkKycStatus}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
                Check KYC Status
            </button>
            {message && <p className="text-sm text-green-500 text-center">{message}</p>}
        </div>
    );
};

export default KycProcess;
