import React, { useState } from 'react';
import axios from 'axios';

const OrderForm = () => {
    const [asset, setAsset] = useState('');
    const [amount, setAmount] = useState('');
    const [orderType, setOrderType] = useState('market');
    const [limitPrice, setLimitPrice] = useState('');
    const [stopPrice, setStopPrice] = useState('');
    const [trailingStopDistance, setTrailingStopDistance] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/orders/create`, {
                asset,
                amount,
                orderType,
                limitPrice,
                stopPrice,
                trailingStopDistance,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setMessage(`Order created successfully: ${data._id}`);
        } catch (error) {
            setMessage('Failed to create order');
        }
    };

    return (
        <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4 sm:space-y-6">
            <h2 className="text-2xl font-bold">Create Order</h2>
            <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
                <div>
                    <label htmlFor="asset" className="block text-sm font-medium text-gray-700">Asset</label>
                    <input
                        type="text"
                        id="asset"
                        value={asset}
                        onChange={(e) => setAsset(e.target.value)}
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label htmlFor="amount" className="block text-sm font-medium text-gray-700">Amount</label>
                    <input
                        type="number"
                        id="amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label htmlFor="orderType" className="block text-sm font-medium text-gray-700">Order Type</label>
                    <select
                        id="orderType"
                        value={orderType}
                        onChange={(e) => setOrderType(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                        <option value="market">Market</option>
                        <option value="limit">Limit</option>
                        <option value="stop-loss">Stop-Loss</option>
                        <option value="take-profit">Take-Profit</option>
                        <option value="trailing-stop">Trailing Stop</option>
                    </select>
                </div>
                {orderType === 'limit' && (
                    <div>
                        <label htmlFor="limitPrice" className="block text-sm font-medium text-gray-700">Limit Price</label>
                        <input
                            type="number"
                            id="limitPrice"
                            value={limitPrice}
                            onChange={(e) => setLimitPrice(e.target.value)}
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                )}
                {orderType === 'stop-loss' && (
                    <div>
                        <label htmlFor="stopPrice" className="block text-sm font-medium text-gray-700">Stop Price</label>
                        <input
                            type="number"
                            id="stopPrice"
                            value={stopPrice}
                            onChange={(e) => setStopPrice(e.target.value)}
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                )}
                {orderType === 'trailing-stop' && (
                    <div>
                        <label htmlFor="trailingStopDistance" className="block text-sm font-medium text-gray-700">Trailing Stop Distance</label>
                        <input
                            type="number"
                            id="trailingStopDistance"
                            value={trailingStopDistance}
                            onChange={(e) => setTrailingStopDistance(e.target.value)}
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                )}
                <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Submit Order
                </button>
                {message && <p className="text-sm text-green-500">{message}</p>}
            </form>
        </div>
    );
};

export default OrderForm;
