import React, { useState } from 'react';
import axios from 'axios';

const AlertSetup = () => {
    const [alertType, setAlertType] = useState('price');
    const [asset, setAsset] = useState('');
    const [threshold, setThreshold] = useState('');
    const [notificationMethod, setNotificationMethod] = useState('email');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/alerts/create`, {
                alertType,
                asset,
                threshold,
                notificationMethod,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setMessage('Alert created successfully');
        } catch (error) {
            setMessage('Failed to create alert');
        }
    };

    return (
        <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4 sm:space-y-6">
            <h2 className="text-2xl font-bold">Setup Alerts</h2>
            <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
                <div>
                    <label htmlFor="alertType" className="block text-sm font-medium text-gray-700">Alert Type</label>
                    <select
                        id="alertType"
                        value={alertType}
                        onChange={(e) => setAlertType(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                        <option value="price">Price Movement</option>
                        <option value="order">Order Execution</option>
                        <option value="strategy">Strategy Performance</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="asset" className="block text-sm font-medium text-gray-700">Asset</label>
                    <input
                        type="text"
                        id="asset"
                        value={asset}
                        onChange={(e) => setAsset(e.target.value)}
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label htmlFor="threshold" className="block text-sm font-medium text-gray-700">Threshold</label>
                    <input
                        type="number"
                        id="threshold"
                        value={threshold}
                        onChange={(e) => setThreshold(e.target.value)}
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label htmlFor="notificationMethod" className="block text-sm font-medium text-gray-700">Notification Method</label>
                    <select
                        id="notificationMethod"
                        value={notificationMethod}
                        onChange={(e) => setNotificationMethod(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                        <option value="email">Email</option>
                        <option value="sms">SMS</option>
                        <option value="in-app">In-App Notification</option>
                    </select>
                </div>
                <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Create Alert
                </button>
                {message && <p className="text-sm text-green-500 mt-4">{message}</p>}
            </form>
        </div>
    );
};

export default AlertSetup;
