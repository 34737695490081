import React, { useState } from 'react';
import axios from 'axios';

const ApiKeyManager = () => {
    const [apiKey, setApiKey] = useState('');
    const [message, setMessage] = useState('');

    const generateApiKey = async () => {
        try {
            const token = localStorage.getItem('token');
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/api-key/generate`, {}, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setApiKey(data.apiKey);
            setMessage('API Key generated successfully');
        } catch (error) {
            setMessage('Failed to generate API Key');
        }
    };

    return (
        <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4 sm:space-y-6">
            <h2 className="text-2xl font-bold text-center">API Key Management</h2>
            <button
                onClick={generateApiKey}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                Generate API Key
            </button>
            {apiKey && (
                <div>
                    <h3 className="text-lg font-semibold text-center">Your API Key:</h3>
                    <p className="bg-gray-100 p-2 rounded text-center">{apiKey}</p>
                </div>
            )}
            {message && <p className="text-sm text-green-500 text-center">{message}</p>}
        </div>
    );
};

export default ApiKeyManager;
