import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AuditLogViewer = () => {
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        const fetchLogs = async () => {
            try {
                const token = localStorage.getItem('token');
                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/audit-logs`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                console.log('Fetched logs:', data); // Ensure the logs are correct
                setLogs(data);
            } catch (error) {
                console.error('Failed to fetch logs:', error.message);
            }
        };
    
        fetchLogs();
    }, []);
    
    

    return (
        <div className="p-4 sm:p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4 sm:space-y-6">
            <h2 className="text-xl sm:text-2xl font-bold text-center mb-4 sm:mb-6">Audit Logs</h2>
            {logs.length > 0 ? (
                <ul className="space-y-2">
                    {logs.map((log, index) => (
                        <li key={index} className="border p-3 sm:p-4 rounded-md">
                            <p className="text-sm sm:text-base"><strong>Action:</strong> {log.action}</p>
                            <p className="text-sm sm:text-base"><strong>Timestamp:</strong> {new Date(log.timestamp).toLocaleString()}</p>
                            <p className="text-sm sm:text-base"><strong>Details:</strong> {JSON.stringify(log.details)}</p>
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="text-center text-gray-500">No logs found.</p>
            )}
        </div>
    );
};

export default AuditLogViewer;
