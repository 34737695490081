// import React, { useState } from 'react';

// const WalletConnect = () => {
//     const [account, setAccount] = useState('');

//     const connectWallet = async () => {
//         if (window.ethereum) {
//             try {
//                 const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
//                 setAccount(accounts[0]);
//             } catch (error) {
//                 console.error('Wallet connection failed:', error);
//             }
//         } else {
//             alert('MetaMask is not installed. Please install it to use this feature.');
//         }
//     };

//     return (
//         <div className="bg-white shadow-md rounded-lg p-6 text-center">
//             <button
//                 onClick={connectWallet}
//                 className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//             >
//                 Connect Wallet
//             </button>
//             {account && (
//                 <p className="mt-4 text-gray-700">
//                     Connected account: <span className="font-medium">{account}</span>
//                 </p>
//             )}
//         </div>
//     );
// };

// export default WalletConnect;



import React, { useContext } from 'react';
import { WalletContext } from '../context/WalletContext';

const WalletConnect = () => {
    const { account, setAccount } = useContext(WalletContext);

    const connectWallet = async () => {
        if (window.ethereum) {
            try {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                setAccount(accounts[0]);
            } catch (error) {
                console.error('Wallet connection failed:', error);
            }
        } else {
            alert('MetaMask is not installed. Please install it to use this feature.');
        }
    };

    return (
        <div className="bg-white shadow-md rounded-lg p-6 text-center">
            <button
                onClick={connectWallet}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                Connect Wallet
            </button>
            {account && (
                <p className="mt-4 text-gray-700">
                    Connected account: <span className="font-medium">{account}</span>
                </p>
            )}
        </div>
    );
};

export default WalletConnect;
