import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { WalletContext } from '../context/WalletContext';

const ExchangeIntegration = () => {
    const [exchangeId, setExchangeId] = useState('binance');
    const [markets, setMarkets] = useState([]);
    const [selectedMarket, setSelectedMarket] = useState('');
    const [orderDetails, setOrderDetails] = useState({ type: 'limit', side: 'buy', amount: 0, price: 0 });
    const [apiKey, setApiKey] = useState('');
    const [secret, setSecret] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [integrationStatus, setIntegrationStatus] = useState('');
    const [integratedExchanges, setIntegratedExchanges] = useState([]);

    const { walletConnected } = useContext(WalletContext);

    const centralizedExchanges = [
        'binance', 'kraken', 'coinbase', 'bitfinex', 'bittrex', 'bitstamp', 'kucoin', 
        'gateio', 'huobi', 'okex', 'poloniex', 'bitmart', 'bybit', 'ftx', 'gemini'
    ];

    const decentralizedExchanges = [
        'uniswap', 'sushiswap', 'pancakeswap', '1inch', 'balancer'
    ];

    const handleSaveIntegration = async () => {
        if (decentralizedExchanges.includes(exchangeId)) {
            setIntegrationStatus('DEXes do not require API integration. Please proceed to trade.');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/exchange/integrate`, {
                exchangeId,
                apiKey,
                secret,
                password,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });

            setIntegrationStatus('Exchange integrated successfully!');
            fetchIntegratedExchanges(); // Fetch updated list of integrated exchanges
        } catch (error) {
            setIntegrationStatus('Failed to integrate exchange: ' + (error.response?.data?.error || error.message));
        }
    };

    const fetchIntegratedExchanges = async () => {
        try {
            const token = localStorage.getItem('token');
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/exchange/integrated`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setIntegratedExchanges(data);
        } catch (error) {
            setMessage('Failed to load integrated exchanges');
        }
    };

    useEffect(() => {
        fetchIntegratedExchanges();
    }, []);

    useEffect(() => {
        const fetchMarkets = async () => {
            try {
                const token = localStorage.getItem('token');
                const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/exchange/markets`, {
                    exchangeId,
                    ...(centralizedExchanges.includes(exchangeId) && { apiKey, secret, password }),
                }, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setMarkets(Object.keys(data));
            } catch (error) {
                setMessage('Failed to load markets');
            }
        };

        if (walletConnected || (apiKey && secret && centralizedExchanges.includes(exchangeId))) {
            fetchMarkets();
        }
    }, [exchangeId, apiKey, secret, walletConnected]);

    const placeOrder = async () => {
        try {
            const token = localStorage.getItem('token');
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/exchange/order`, {
                exchangeId,
                ...(centralizedExchanges.includes(exchangeId) && { apiKey, secret, password }),
                symbol: selectedMarket,
                ...orderDetails,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setMessage(`Order placed successfully: ${data.id}`);
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'Failed to place order. Please check your balance or API limits.';
            setMessage(errorMessage);
        }
    };
    

    return (
        <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4 sm:space-y-6">
            <h2 className="text-2xl font-bold">Exchange Integration</h2>

            {/* Displaying Integrated Exchanges */}
            {integratedExchanges.length > 0 && (
                <div>
                    <h3 className="text-xl font-bold">Integrated Exchanges</h3>
                    <ul>
                        {integratedExchanges.map((exchange, index) => (
                            <li key={index}>
                                {exchange.exchangeId.charAt(0).toUpperCase() + exchange.exchangeId.slice(1)}
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                    <label htmlFor="exchangeId" className="block text-sm font-medium text-gray-700">Select Exchange</label>
                    <select
                        id="exchangeId"
                        value={exchangeId}
                        onChange={(e) => setExchangeId(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                        <optgroup label="Centralized Exchanges">
                            {centralizedExchanges.map((exchange) => (
                                <option key={exchange} value={exchange}>
                                    {exchange.charAt(0).toUpperCase() + exchange.slice(1)}
                                </option>
                            ))}
                        </optgroup>
                        <optgroup label="Decentralized Exchanges">
                            {decentralizedExchanges.map((exchange) => (
                                <option key={exchange} value={exchange}>
                                    {exchange.charAt(0).toUpperCase() + exchange.slice(1)}
                                </option>
                            ))}
                        </optgroup>
                    </select>
                </div>

                {centralizedExchanges.includes(exchangeId) && (
                    <>
                        <div>
                            <label htmlFor="apiKey" className="block text-sm font-medium text-gray-700">API Key</label>
                            <input
                                type="text"
                                id="apiKey"
                                value={apiKey}
                                onChange={(e) => setApiKey(e.target.value)}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                        <div>
                            <label htmlFor="secret" className="block text-sm font-medium text-gray-700">Secret</label>
                            <input
                                type="text"
                                id="secret"
                                value={secret}
                                onChange={(e) => setSecret(e.target.value)}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password (Optional)</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                    </>
                )}
            </div>

            <button
                onClick={handleSaveIntegration}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                {decentralizedExchanges.includes(exchangeId) ? 'Proceed to Trade' : 'Save Integration'}
            </button>
            {integrationStatus && <p className="text-sm text-green-500">{integrationStatus}</p>}

            {markets.length > 0 && (
                <>
                    <div className="mt-4">
                        <label htmlFor="market" className="block text-sm font-medium text-gray-700">Select Market</label>
                        <select
                            id="market"
                            value={selectedMarket}
                            onChange={(e) => setSelectedMarket(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                            {markets.map((market, index) => (
                                <option key={index} value={market}>{market}</option>
                            ))}
                        </select>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
                        <div>
                            <label htmlFor="type" className="block text-sm font-medium text-gray-700">Order Type</label>
                            <select
                                id="type"
                                value={orderDetails.type}
                                onChange={(e) => setOrderDetails({ ...orderDetails, type: e.target.value })}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                <option value="limit">Limit</option>
                                <option value="market">Market</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="side" className="block text-sm font-medium text-gray-700">Order Side</label>
                            <select
                                id="side"
                                value={orderDetails.side}
                                onChange={(e) => setOrderDetails({ ...orderDetails, side: e.target.value })}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                <option value="buy">Buy</option>
                                <option value="sell">Sell</option>
                            </select>
                        </div>
                        {orderDetails.type === 'limit' && (
                            <div>
                                <label htmlFor="price" className="block text-sm font-medium text-gray-700">Price</label>
                                <input
                                    type="number"
                                    id="price"
                                    value={orderDetails.price}
                                    onChange={(e) => setOrderDetails({ ...orderDetails, price: e.target.value })}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                        )}
                        <div>
                            <label htmlFor="amount" className="block text-sm font-medium text-gray-700">Amount</label>
                            <input
                                type="number"
                                id="amount"
                                value={orderDetails.amount}
                                onChange={(e) => setOrderDetails({ ...orderDetails, amount: e.target.value })}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                    </div>
                    <button
                        onClick={placeOrder}
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
                    >
                        Place Order
                    </button>
                    {message && <p className="text-sm text-green-500">{message}</p>}
                </>
            )}
        </div>
    );
};

export default ExchangeIntegration;
