import React, { useState } from 'react';
import axios from 'axios';

const ReportGenerator = () => {
    const [reportType, setReportType] = useState('trade');
    const [message, setMessage] = useState('');

    const handleDownload = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/analytics/report`, { reportType }, {
                headers: { Authorization: `Bearer ${token}` },
                responseType: 'blob', // Important to receive the file as a blob
            });

            // Create a URL for the file
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `report-${reportType}.pdf`);
            document.body.appendChild(link);
            link.click();
            setMessage('Report downloaded successfully');
        } catch (error) {
            setMessage('Failed to download report');
        }
    };

    return (
        <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4 sm:space-y-6">
            <h2 className="text-2xl font-bold text-center">Generate Report</h2>
            <div>
                <label htmlFor="reportType" className="block text-sm font-medium text-gray-700">Report Type</label>
                <select
                    id="reportType"
                    value={reportType}
                    onChange={(e) => setReportType(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                    <option value="trade">Trade Activity</option>
                    <option value="portfolio">Portfolio Analysis</option>
                </select>
            </div>
            <button
                onClick={handleDownload}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                Download Report
            </button>
            {message && <p className="text-sm text-green-500 text-center">{message}</p>}
        </div>
    );
};

export default ReportGenerator;
