import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PortfolioAnalysis = () => {
    const [analysis, setAnalysis] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAnalysis = async () => {
            try {
                const token = localStorage.getItem('token');
                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/analytics/portfolio`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setAnalysis(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching portfolio analysis:', error);
                setLoading(false);
            }
        };

        fetchAnalysis();
    }, []);

    if (loading) {
        return <p className="text-center text-gray-500">Loading portfolio analysis...</p>;
    }

    return (
        <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4 sm:space-y-6">
            <h2 className="text-2xl font-bold">Portfolio Analysis</h2>
            <p>Total Value: ${analysis.totalValue?.toLocaleString() || 'N/A'}</p>
            <h3 className="text-xl font-semibold">Asset Allocation</h3>
            <ul className="list-disc list-inside text-gray-700">
                {Object.keys(analysis.assetAllocation || {}).map((asset) => (
                    <li key={asset} className="mb-2">
                        {asset}: ${analysis.assetAllocation[asset]?.toLocaleString()}
                    </li>
                ))}
            </ul>
            <p>Risk Exposure: ${analysis.riskExposure?.toLocaleString() || 'N/A'}</p>
        </div>
    );
};

export default PortfolioAnalysis;
