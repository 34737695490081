import React, { useState } from 'react';
import axios from 'axios';

const StrategyBuilder = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [parameters, setParameters] = useState({});
    const [parameterKey, setParameterKey] = useState('');
    const [parameterValue, setParameterValue] = useState('');
    const [message, setMessage] = useState('');

    const handleAddParameter = () => {
        if (parameterKey && parameterValue) {
            setParameters((prevParams) => ({
                ...prevParams,
                [parameterKey]: parameterValue,
            }));
            setParameterKey('');
            setParameterValue('');
        }
    };

    const createStrategy = async () => {
        try {
            const token = localStorage.getItem('token');
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/strategies/create`, {
                name,
                description,
                parameters,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setMessage('Strategy created successfully!');
        } catch (error) {
            setMessage('Failed to create strategy');
        }
    };

    return (
        <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-bold mb-4">Create Custom Strategy</h2>
            <div className="mb-4">
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Strategy Name"
                    className="block w-full px-3 py-2 mb-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Strategy Description"
                    className="block w-full px-3 py-2 mb-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
            </div>
            <div className="mb-6">
                <h3 className="text-xl font-semibold mb-2">Parameters</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <input
                        type="text"
                        value={parameterKey}
                        placeholder="Parameter Name"
                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        onChange={(e) => setParameterKey(e.target.value)}
                    />
                    <input
                        type="text"
                        value={parameterValue}
                        placeholder="Parameter Value"
                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        onChange={(e) => setParameterValue(e.target.value)}
                    />
                </div>
                <button
                    onClick={handleAddParameter}
                    className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md"
                >
                    Add Parameter
                </button>
                <ul className="mt-4">
                    {Object.entries(parameters).map(([key, value]) => (
                        <li key={key} className="text-sm text-gray-600">{key}: {value}</li>
                    ))}
                </ul>
            </div>
            <button
                onClick={createStrategy}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                Create Strategy
            </button>
            {message && <p className="mt-4 text-center text-gray-700">{message}</p>}
        </div>
    );
};

export default StrategyBuilder;
