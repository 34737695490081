import React, { useState } from 'react';
import axios from 'axios';

const CopyTrading = () => {
    const [leaderId, setLeaderId] = useState('');
    const [message, setMessage] = useState('');

    const handleFollow = async () => {
        try {
            const token = localStorage.getItem('token');
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/copy-trade/follow`, { leaderId }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setMessage('Successfully followed the trader');
        } catch (error) {
            setMessage('Failed to follow the trader');
        }
    };

    return (
        <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4 sm:space-y-6">
            <h2 className="text-2xl font-bold text-center">Copy Trading</h2>
            <div>
                <label htmlFor="leaderId" className="block text-sm font-medium text-gray-700">Trader ID to Follow</label>
                <input
                    type="text"
                    id="leaderId"
                    value={leaderId}
                    onChange={(e) => setLeaderId(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
            </div>
            <button
                onClick={handleFollow}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                Follow Trader
            </button>
            {message && <p className="text-sm text-green-500 mt-2">{message}</p>}
        </div>
    );
};

export default CopyTrading;
