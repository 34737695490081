import React, { useState, useEffect } from 'react';
import axios from 'axios';

const IPWhitelist = () => {
    const [ip, setIP] = useState('');
    const [whitelist, setWhitelist] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchWhitelist = async () => {
            try {
                const token = localStorage.getItem('token');
                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/ip-whitelist`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setWhitelist(data.allowedIPs);
            } catch (error) {
                setMessage('Failed to fetch whitelist');
            }
        };

        fetchWhitelist();
    }, []);

    const addIP = async () => {
        try {
            const token = localStorage.getItem('token');
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/ip-whitelist/add`, { ip }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setWhitelist(data.allowedIPs);
            setIP('');
            setMessage('IP added to whitelist');
        } catch (error) {
            setMessage('Failed to add IP');
        }
    };

    const removeIP = async (ipToRemove) => {
        try {
            const token = localStorage.getItem('token');
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/ip-whitelist/remove`, { ip: ipToRemove }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setWhitelist(data.allowedIPs);
            setMessage('IP removed from whitelist');
        } catch (error) {
            setMessage('Failed to remove IP');
        }
    };

    return (
        <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4 sm:space-y-6">
            <h2 className="text-2xl font-bold">IP Whitelisting</h2>
            <div>
                <label htmlFor="ip" className="block text-sm font-medium text-gray-700">Add IP Address</label>
                <input
                    type="text"
                    id="ip"
                    value={ip}
                    onChange={(e) => setIP(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="e.g., 192.168.1.1"
                />
            </div>
            <button
                onClick={addIP}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                Add IP
            </button>
            <ul className="space-y-2">
                {whitelist.map((ipAddress, index) => (
                    <li key={index} className="flex justify-between items-center bg-gray-100 p-2 rounded">
                        <span>{ipAddress}</span>
                        <button
                            onClick={() => removeIP(ipAddress)}
                            className="text-red-500 hover:text-red-700 text-sm"
                        >
                            Remove
                        </button>
                    </li>
                ))}
            </ul>
            {message && <p className="text-sm text-green-500">{message}</p>}
        </div>
    );
};

export default IPWhitelist;
