import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PerformanceMetrics = () => {
    const [metrics, setMetrics] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchMetrics = async () => {
            try {
                const token = localStorage.getItem('token');
                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/performance/metrics`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setMetrics(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching performance metrics:', error);
                setLoading(false);
            }
        };

        fetchMetrics();
    }, []);

    if (loading) {
        return <p className="text-center text-gray-500">Loading...</p>;
    }

    return (
        <div className="bg-white shadow-md rounded-lg p-6 max-w-lg mx-auto">
            <h2 className="text-2xl font-bold mb-4">Performance Metrics</h2>
            <div className="mb-6">
                <p className="text-lg">
                    <span className="font-semibold">Portfolio Growth:</span> ${metrics.portfolioGrowth?.toLocaleString() || 'N/A'}
                </p>
                <p className="text-lg">
                    <span className="font-semibold">ROI:</span> {metrics.roi !== undefined ? `${metrics.roi.toFixed(2)}%` : 'N/A'}
                </p>
            </div>
            <h3 className="text-xl font-semibold mb-2">Trading History:</h3>
            <ul className="list-disc list-inside text-gray-700">
                {metrics.tradingHistory?.length > 0 ? (
                    metrics.tradingHistory.map(trade => (
                        <li key={trade._id} className="mb-2">
                            <span className={`font-medium ${trade.type === 'buy' ? 'text-green-500' : 'text-red-500'}`}>
                                {trade.type === 'buy' ? 'Bought' : 'Sold'}
                            </span> {trade.asset} - {trade.amount}
                        </li>
                    ))
                ) : (
                    <li className="text-gray-500">No trading history available.</li>
                )}
            </ul>
        </div>
    );
};

export default PerformanceMetrics;
