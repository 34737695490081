import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const CommunityChat = () => {
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const ws = useRef(null);
    const chatBoxRef = useRef(null);

    useEffect(() => {
        ws.current = new WebSocket('ws://localhost:5000');

        ws.current.onopen = () => {
            console.log('Connected to WebSocket server');
        };

        ws.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === 'history') {
                setMessages(data.data);
            } else if (data.type === 'newMessage') {
                setMessages((prevMessages) => [...prevMessages, data.data]);
            }
        };

        ws.current.onclose = () => {
            console.log('Disconnected from WebSocket server');
        };

        ws.current.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        return () => {
            ws.current.close();
        };
    }, []);

    useEffect(() => {
        // Scroll to the latest message when the messages array is updated
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [messages]);

    const sendMessage = async () => {
        try {
            const token = localStorage.getItem('token');
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/me`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const chatMessage = {
                username: data.username,
                content: message,
            };

            ws.current.send(JSON.stringify(chatMessage));
            setMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    return (
        <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4 sm:space-y-6">
            <h2 className="text-2xl font-bold">Community Chat</h2>
            <div ref={chatBoxRef} className="h-64 overflow-y-scroll border p-4 rounded-md">
                <ul>
                    {messages.map((msg, index) => (
                        <li key={index} className="mb-2">
                            <strong>{msg.username}:</strong> {msg.content} <br />
                            <small>{msg.timestamp}</small>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="flex space-x-4">
                <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
                    className="flex-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <button
                    onClick={sendMessage}
                    className="w-1/3 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Send
                </button>
            </div>
        </div>
    );
};

export default CommunityChat;
