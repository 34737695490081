import React, { useEffect, useState } from 'react';
import axios from 'axios';

const TradeAnalytics = () => {
    const [analytics, setAnalytics] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAnalytics = async () => {
            try {
                const token = localStorage.getItem('token');
                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/analytics/trade`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setAnalytics(data || {});
            } catch (error) {
                console.error('Error fetching trade analytics:', error);
                alert('Failed to load trade analytics.');
            } finally {
                setLoading(false);
            }
        };

        fetchAnalytics();
    }, []);

    if (loading) {
        return <p className="text-center text-gray-500">Loading analytics...</p>;
    }

    const winLossRatio = analytics.winLossRatio !== null && analytics.winLossRatio !== undefined ? analytics.winLossRatio.toFixed(2) : 'N/A';
    const avgTradeDuration = analytics.avgTradeDuration !== null && analytics.avgTradeDuration !== undefined ? analytics.avgTradeDuration.toFixed(2) : 'N/A';
    const riskRewardRatio = analytics.riskRewardRatio !== null && analytics.riskRewardRatio !== undefined ? analytics.riskRewardRatio.toFixed(2) : 'N/A';

    return (
        <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4 sm:space-y-6">
            <h2 className="text-2xl font-bold text-center">Trade Analytics</h2>
            <div className="text-lg">
                <p><span className="font-semibold">Win/Loss Ratio:</span> {winLossRatio}</p>
                <p><span className="font-semibold">Avg Trade Duration:</span> {avgTradeDuration} minutes</p>
                <p><span className="font-semibold">Risk/Reward Ratio:</span> {riskRewardRatio}</p>
            </div>
        </div>
    );
};

export default TradeAnalytics;
